import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const PolicyParagraph = memo(function PolicyParagraph({
  description,
  title,
}: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 144px 10vw;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  line-height: 5.25rem;
  margin-bottom: 0.75rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 0;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 2.25rem;

  p {
    margin: 2rem 0;
  }
  a {
    text-decoration: underline;
  }
  ul {
    list-style: initial;
    padding-left: 1.125rem;
  }
`
